$almost-black: #0f0f0f;
$deep-blue: #000D4C;        // Blu scuro
$header-menu-bgc: #030375;  // (blu solo per fondo menù)
$electric-blue: #0026FF;    // Blu elettrico
$fluo-green: #33E578;       // Verde Fluo
$fluo-yellow: #fff300;      // Giallo Fluo (es: in Careers)
$sim-red: #FF0D21;          // Rosso SIM
$lighter-grey: #A0AAAA;
$almost-white: #f5f6f6;     // Grigio chiaro (usato per fondi; è il "10% di lighter-grey")

$input-bgc:#353591;
$input-error-border-color: #ff0000;
$input-error-color: rgb(253, 166, 166);

/** DERIVATED COLORS */
$deep-blue-20perc: transparentize($color: $deep-blue, $amount: 0.8);
$fluo-green-20perc: transparentize($color: $fluo-green, $amount: 0.8);
$fluo-green-10perc: transparentize($color: $fluo-green, $amount: 0.9);
$fluo-yellow-20perc: transparentize($color: $fluo-yellow, $amount: 0.8);
$almost-white-20perc: transparentize($color: $almost-white, $amount: 0.8);
$sim-red-20perc: transparentize($color: $sim-red, $amount: 0.8);
$sim-red-10perc: transparentize($color: $sim-red, $amount: 0.9);

/** GRADIENTS */
$gradient-blue-green: linear-gradient(135deg, $deep-blue 40%, $fluo-green 80%);
$gradient-blue-red: linear-gradient(135deg, $deep-blue 40%, $sim-red 80%);
$gradient-blue-red-semiopaque: linear-gradient(135deg, transparentize($deep-blue, 0.2) 40%, transparentize($sim-red, 0.4) 80%);
$gradient-blue-red-semitransparent: linear-gradient(135deg, transparentize($deep-blue, 0.4) 40%, transparentize($sim-red, 0.7) 80%);
$gradient-blue-red-20perc: linear-gradient(135deg, $deep-blue-20perc 40%, $sim-red-20perc 80%);
