@use './fonts' as *;
@use './colors' as *;
@use './variables' as *;

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use './m3-theme.scss' as *;

@use '@angular/material' as mat;

// Customize the entire app. Change :root to your selector if you want to scope the styles.
:root {
  @include mat.slide-toggle-overrides((
    // https://material.angular.io/components/slide-toggle/styling
    track-outline-color: transparent,
    selected-track-outline-color: transparent,

    selected-track-color: transparentize($fluo-green, 0.7),
    selected-focus-track-color: transparentize($fluo-green, 0.7),
    selected-pressed-track-color: transparentize($fluo-green, 0.7),
    selected-hover-track-color: transparentize($fluo-green, 0.7),

    selected-handle-color: $fluo-green,
    selected-focus-handle-color: $fluo-green,
    selected-pressed-handle-color: $fluo-green,
    selected-hover-handle-color: $fluo-green,

    unselected-track-color: transparentize(grey, 0.5),
    unselected-focus-track-color: transparentize(grey, 0.5),
    unselected-pressed-track-color: transparentize(grey, 0.5),
    unselected-hover-track-color: transparentize(grey, 0.5),

    unselected-handle-color: transparentize(grey, 0.2),
    unselected-focus-handle-color: transparentize(grey, 0.2),
    unselected-pressed-handle-color: transparentize(grey, 0.2),
    unselected-hover-handle-color: transparentize(grey, 0.2),

    selected-icon-color: $deep-blue,
    unselected-icon-color: transparent,

    disabled-selected-handle-color: $fluo-green,
    disabled-selected-icon-color: $deep-blue,
    disabled-selected-icon-opacity: 1,
    disabled-selected-handle-opacity: 1,

    /** with these two lines below, disabled slider will look like if it's enabled */
    // disabled-track-opacity: 1,
    // disabled-selected-track-color: transparentize($fluo-green, 0.7),
  ));
}

/* You can add global styles to this file, and also import other style files */

@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import './grid_system';


/** Angular Material Overrides - start */

button.mdc-button {
  .mdc-button__label {
    font-family: 'Neutra2Text-Demi';
    letter-spacing: 0.1em;
    font-weight: 700;
  }
}

.header {
  button.mat-mdc-icon-button:hover .mat-icon {
    color: $fluo-green;
  }

  button.mat-mdc-button .mat-icon {
    margin-left: 2px;
  }

  button.mat-mdc-button:hover .mdc-button__label,
  button.mat-mdc-button:hover .mat-icon {
    color: $fluo-green;
  }

  .btn-container.change-language button.mdc-button {
    padding-right: 0;
  }
}

.sim-header {
  button.mat-mdc-icon-button:hover .mat-icon {
    color: $sim-red;
  }

  button.mat-mdc-button:hover .mdc-button__label,
  button.mat-mdc-button:hover .mat-icon {
    color: $sim-red;
  }
}

.search-box-input,
.careers-selector-search-box-input {
  &-element {
    .mat-mdc-text-field-wrapper {
      background-color: $almost-white-20perc !important;
      border-radius: 30px;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
      color: $almost-white !important;
      caret-color: $almost-white !important;
    }

    .mat-mdc-form-field-subscript-wrapper {
      height: 0;
    }

    .mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix {
      padding-top: 10px;
      padding-bottom: 10px;

      @media screen and (max-width: $screen_size_mobile_vertical) {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
    
    .mat-mdc-form-field-flex {
      height: calc(2.4em + 5px);

      @media screen and (max-width: $screen_size_mobile_vertical) {
        height: calc(1.6em + 5px);
      }
    }

    .mdc-line-ripple {
      display: none;
    }
  }
}

.default-selector-search-box-input {
  &-element {
    .mat-mdc-text-field-wrapper {
      background-color: $deep-blue-20perc !important;
      border-radius: 30px;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
      color: $deep-blue !important;
      caret-color: $deep-blue !important;
    }

    .mat-mdc-form-field-subscript-wrapper {
      height: 0;
    }

    .mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    
    .mat-mdc-form-field-flex {
      height: calc(2.4em + 5px);

      @media screen and (max-width: $screen_size_mobile_vertical) {
        height: calc(1.6em + 5px);
      }
    }

    .mdc-line-ripple {
      display: none;
    }
  }
}

.dialog-contact {
  &-input-element {
    height: 34px;

    .mat-mdc-text-field-wrapper {
      // background-color: transparentize(white, 0.4) !important;
      border-radius: 30px;
    }

    &.field-error {
      .mat-mdc-text-field-wrapper {
        background-color: $input-error-color !important;
        border: 1px solid $input-error-border-color;
      }
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input,
    .mat-mdc-select-value .mat-mdc-select-value-text {
      color: $deep-blue !important;
      caret-color: $deep-blue !important;
    }

    .mat-mdc-form-field-subscript-wrapper {
      height: 0;
    }

    .mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    
    .mat-mdc-form-field-flex {
      height: calc(2.4em + 5px);
    }

    .mdc-line-ripple {
      display: none;
    }
  }

  .mat-mdc-checkbox {
    .mdc-checkbox {
      margin-top: -2px;
    }

    .mdc-checkbox__background {
      // background-color: transparentize(white, 0.4) !important;
      border: none;
    }

    &.field-error {
      .mdc-checkbox__background {
        background-color: $input-error-color !important;
        border: 1px solid $input-error-border-color;
      }
    }

    .mdc-label {
      font-size: 0.8em;
      color: $deep-blue;
  
      a {
        font-size: 1em;
        font-weight: 900;
        color: $deep-blue;
      }
    }
  }
}
.dialog-contact.theme--default {
  .dialog-contact-input-element {
    .mat-mdc-text-field-wrapper {
      background-color: transparentize(white, 0.4) !important;
    }
  }

  .mat-mdc-checkbox {
    .mdc-checkbox__background {
      background-color: transparentize(white, 0.4) !important;
    }
  }
}
.dialog-contact.theme--white {
  .dialog-contact-input-element {
    .mat-mdc-text-field-wrapper {
      background-color: transparentize(grey, 0.8) !important;
    }
  }

  .mat-mdc-checkbox {
    .mdc-checkbox__background {
      background-color: transparentize(grey, 0.8) !important;
    }
  }
}

/** Angular Material Overrides - end */

html, body { height: 100%; overscroll-behavior: none; }

body {
  overflow-x: hidden;

  background-color: $deep-blue;

  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;

  font-size: 10px;  // base font-size, all font-size must be expressed in "em"

  @media screen and (max-width: $screen_size_ipda_air) {
    font-size: 8px;
  }

  @media screen and (max-width: $screen_size_mobile_vertical) {
    font-size: 8px;
  }
}

.page {
  .page-content {
    // padding-top: $header-height;
    color: white;
  }
}

h1 {
  font-size: 8em;
  margin-top: 40px;
  margin-bottom: 50px;

  font-family: 'Inter24pt-Black';
  font-weight: normal;
  font-style: normal;

  // letter-spacing: 1px; // Inter24pt doesn't have this

  @media screen and (max-width: $screen_size_mobile_vertical) {
    font-size: 4.5em;
  }
}

h2 {
  font-size: 3.5em;
  margin-top: 20px;
  margin-bottom: 20px;

  font-weight: 900;
  font-style: normal;
}

div.category h2 {
  font-size: 2.2em;
  margin-top: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: $fluo-green;

  font-family: 'Neutra2Text';
  font-weight: 600;
  letter-spacing: 0.1em;
}

div.subtitle h2 {
  font-family: 'Inter24pt-SemiBold';
  font-weight: normal;
  line-height: 1.4em;

  @media screen and (max-width: $screen_size_mobile_vertical) {
    font-size: 2.4em;
    line-height: 1.4em;
  }
}

h3 {
  font-size: 4.5em;

  font-family: 'Inter24pt-Black';
  font-weight: normal;
  font-style: normal;

  // letter-spacing: 1px; // Inter24pt doesn't have this

  @media screen and (max-width: $screen_size_mobile_vertical) {
    font-size: 3.5em;
  }
}

h4 {
  font-size: 2.4em;

  font-family: 'Inter24pt-Bold';
  font-weight: normal;

  // letter-spacing: 0;
  
  @media screen and (max-width: $screen_size_mobile_vertical) {
    font-size: 3em;
  }
}

h5 {
  font-size: 1.4em;
  font-family: 'Neutra2Text';
  font-weight: 600;

  letter-spacing: 0.2px;

  @media screen and (max-width: $screen_size_mobile_vertical) {
    font-size: 2em;
  }
}

p, a, label {
  font-size: 2.4em;
  line-height: 1.4em;

  font-family: 'Inter24pt-Regular';
  font-weight: 400;
  font-style: normal;

  b,
  strong {
    font-family: 'Inter24pt-Bold';
    font-weight: normal;
  }

  @media screen and (max-width: $screen_size_mobile_vertical) {
    font-size: 1.9em;
  }
}

label {
  font-family: 'Inter24pt-Medium';
}

ul,
ol {
  font-family: 'Inter24pt-Regular';
  font-weight: 400;
  font-style: normal;
}

p a {
  font-size: 1em;
}

.interactive {
  cursor: pointer;
}

.not-interactive {
  cursor: auto !important;
}

a.not-interactive {
  cursor: default;
}

a.unstyled-anchor {
  cursor: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  text-decoration: inherit;
}

.underline {
  text-decoration: underline;
}

.hidden {
  display: none;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.capslock {
  text-transform: uppercase;
}

.text--green {
  color: $fluo-green;
}

.text--deep-blue {
  color: $deep-blue;
}

.text--electric-blue {
  color: $electric-blue;
}

.text--yellow {
  color: $fluo-yellow;
}

.justified {
  text-align: justify;
}

.desktop-only {
  @media screen and (max-width: $screen_size_ipda_air) {
    display: none;
  }
}

.default-card-border {
  border: 2px solid $fluo-green;
  border-radius: 38px;
  padding: 60px;

  @media screen and (max-width: $screen_size_mobile_vertical) {
    padding: 30px;
  }
}

.default-shadow {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

.flex-vertical-centered {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: $screen_size_ipda_air) {
    flex-wrap: wrap;
  }

  &--centered {
    justify-content: center !important;
  }

  &--space-between {
    justify-content: space-between !important;
  }
}

/** 
  removes extra pixels at bottom of image/video only sections
  https://stackoverflow.com/a/19212391
*/
.fix-bottom-extra-pixels {
  font-size: 0;

  @media screen and (max-width: $screen_size_mobile_vertical) {
    margin-bottom: -1px;
  }
}

/** css classes for simplified arrow (SVG) */
.simplified-arrow-down,
.simplified-arrow-more {
  transform: translate3d(0, +45%, 0);
}
.simplified-arrow-up,
.simplified-arrow-less {
  transform: rotate3d(0, 0, 1, 180deg) translate3d(0, -55%, 0);
}

.overlay-backdrop,
.cdk-overlay-dark-backdrop {
  background: transparentize($deep-blue, 0.1);
}

.cdk-global-scrollblock {   // fixes weird bug with mat-dialog (now, when the overlay is open, makes the page visible)
  position: relative;
  overflow-y: visible;
}


.inner-html {
  max-width: 100%;

  h1, h2, h3, h4, h5 {
    margin-top: 20px;
    margin-bottom: 20px;
    color: $deep-blue;
  }

  ul {
    li::marker {
      color: $fluo-green;
    }

    li {
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }

  &--insights-article {
    h1, h2, h3, h4, h5 {
      text-transform: uppercase;

      font-family: 'Inter24pt-ExtraBold';
      font-weight: normal;

      a {
        display: inline-block;
      }
    }

    h2 {
      font-size: 2.3em;
    }

    h3 {
      font-size: 2.0em;
    }

    h4 {
      font-size: 1.8em;
    }

    /** images' notes or footnotes */
    h6 {
      font-size: 1.3em;
      line-height: 1.4em;
      font-style: italic;
      font-weight: 100;
      margin-top: 12px;
      margin-bottom: 12px;

      color: $lighter-grey;

      a {
        color: $electric-blue;
        font-size: 1em;
      }
    }
  
    p {
      font-size: 1.9em;
      line-height: 1.4em;
    }
  
    p ul,
    p ol {
      font-size: 1em;
    }

    ul,
    ol {
      margin-left: 60px;
      margin-top: 30px;

      @media screen and (max-width: $screen_size_mobile_vertical) {
        margin-left: 0;
        padding-left: 22px;
      }
  
      li {
        margin-top: 10px;
        margin-bottom: 30px;
        font-size: 1.9em;
        line-height: 1.4em;
        font-weight: 500;

        li {
          font-size: 1em;
        }
      }
    
      li::marker {
        font-size: 1.4em;
      }

      li a {
        font-size: 1em;
      }
    }

    ol li::marker {
      font-size: 1em;
    }

    blockquote {
      font-family: 'Inter24pt-Black';
      font-weight: normal;
      font-size: 2.4em;
      line-height: 1.4em;
      padding-left: 40px;
      margin-top: 60px;
      margin-bottom: 60px;

      @media screen and (max-width: $screen_size_mobile_vertical) {
        padding-left: 0;
        margin-left: 10px;
        margin-right: 10px;
      }

      p {
        font-size: 1em;
      }
    }

    img {
      max-width: 100%;
      width: 100%;
      margin-top: 40px;
      margin-bottom: 40px;
      object-fit: contain;
      height: auto;

      @media screen and (max-width: $screen_size_mobile_vertical) {
        width: 100% !important;
        height: auto !important;
      }
    }

    /** removes margin-bottom from images if it's followed by an h6 */
    img:has(+ h6) {
      margin-bottom: 0px;
    }

    h6 {
      margin-bottom: 50px;
    }

    iframe {
      max-width: 100%;

      &.tableauViz {
        width: 100%;
      }
    }

    .table-of-contents {
      margin-top: 60px;
      margin-bottom: 60px;

      ul {
        list-style-type: none;
        margin-left: 0px;
        padding-left: 0px;
        margin-top: 0;

        li {
          margin-top: 1px;
          margin-bottom: 1px;
        }

        a {
          font-size: 1em;
        }
      }

      ul ul {
        font-size: 1em;
        margin-left: 40px;
      }
    }
  }

  &--open-positions-article {
    ul {
      li::marker {
        color: $fluo-yellow;
      }
    }
  }
  
  &--sim-article,             // SimArticleComponent
  &--sim-general-article {    // ArticlePageComponent
    ul {
      li {
        font-size: 1.6em;
        line-height: 1.4em;

        li {
          font-size: 1em;
        }
      }

      li::marker {
        color: $sim-red;
      }
    }

    p ul,
    p ol {
      font-size: 1em;
    }

    a {
      color: $sim-red;
    }
  }

  &--sim-article {            // SimArticleComponent
    a {
      &::after {
        content: ' ↗';
        color: $sim-red;
      }
    }
  }
}


/** fix for mobile header position */
.cdk-overlay-connected-position-bounding-box {
  top: $mobile-header-height - 1px !important;
}



.cdk-overlay-container {
  z-index: $z_cdk_overlay_container !important;

  .language-tooltip * {
    padding: 10px 20px !important;
    line-height: 1.5em !important;
    font-size: 14px !important;
    font-weight: 900 !important;
    // background-color: $deep-blue !important;
    // color: $fluo-green !important;
  }
}


#modal-ar-login{
  --mdc-dialog-container-shape: 4px;
  font-family: "Neutra2Text";
  amplify-authenticator {
    .amplify-tabs {
      display: none;
    }
  
    fieldset {
      border: 0;
      margin: 0;
      padding: 0;
      legend {
        display: none;
      }
    }
  
    amplify-form-field {
      input {
        display: block;
        width: 100%;
        padding: 1rem;
        background-color: transparent;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 3px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        height: 3.125rem;
        line-height: 1.1;
        margin-bottom: 20px;
        &::placeholder {
          color: transparent;
          opacity: 0; /* Firefox */
        }
        
        &::-ms-input-placeholder { /* Edge 12 -18 */
          color: transparent;
        }
        &:focus, &:focus-visible{
          border-color: $header-menu-bgc;
          outline: none;
        }
      }
    }
  }

  .amplify-passwordfield{
    .amplify-field-group__outer-end{
      display: none;
    }
  }

  button[type=submit]{
    margin-top: 50px;
    right: 54px;
    position: absolute;
    background: $deep-blue;
    color: #fff;
    padding: 16px;
    font-size: 14px;
    width: 153px;
    
    
  }
  button[type=submit][ng-reflect-is-disabled=true]{
    opacity: 0.5; 
  }
  amplify-slot{
    margin-top: -11px;
    button{
      margin-top: -29px;
      background: transparent;
      border: 0;
      cursor: pointer;
      color: $deep-blue;
      font-size: 11px;
      padding: 0;
      letter-spacing: 0.7px;
    }
  }

  .ar-dialog-auth--text{
    a {
      color: $deep-blue;
      font-size: 18px;
      font-weight: 400;
      font-style: normal;
  }
  }

  amplify-error{
    svg{
      max-height: 20px;
    }
    .amplify-flex{
      color: red;
      width: 100%;
      display: flex;
      gap: 8px;
      margin-bottom: 10px;
    }
  }
}
