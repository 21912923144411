// $screen_size_ipda_pro: 1366px;
$screen_size_ipda_air: 1180px;
$screen_size_mobile_vertical: 430px;    // iphone_14_pro_max

$header_footer_trashold: 30px;

$screen_size_small_header: ($screen_size_ipda_air + $header_footer_trashold);


$z_cdk_overlay_container: 9999;
$z_video_embedder: 1000;
$z_header: 999;

$header-height: 80px;
$header-logo-height: 50px;
$mobile-header-height: 50px;
$mobile-logo-height: 44px;

$header_content_max_width: 1415px;
$section_content_max_width: 1100px;
$section-news_content_max_width: 1260px;

$mobile_horizontal_padding: 30px;
$tablet_horizontal_padding: $mobile_horizontal_padding * 1.5;

$mobile_section_padding_top: 55px;
$mobile_section_padding_bottom: 60px;

$shadow_horizontal: 10px;
$shadow_vertical: 10px;
$shadow_blur_radius: 15px;
$shadow_spread_radius: -15px;
$shadow: $shadow_horizontal $shadow_vertical $shadow_blur_radius $shadow_spread_radius rgba(0,0,0,0.75);
