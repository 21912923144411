// @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@font-face {
    font-family: 'Neutra2Text';
    src: url('/assets/fonts/Neutra2Text-Book.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Neutra2Text';
    src: url('/assets/fonts/Neutra2Text-Italic.otf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Neutra2Text';
    src: url('/assets/fonts/Neutra2Text-Demi.otf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Neutra2Text';
    src: url('/assets/fonts/Neutra2Text-Bold.otf') format('truetype');
    font-weight: bold;
    font-style: normal;
}


/** --- NEUTRA --- */
@font-face {
    font-family: "Neutra2Text-Bold";
    src: url('/assets/fonts/Neutra2Text-Bold.ttf') format("truetype");
}

@font-face {
    font-family: "Neutra2Text-Book";
    src: url('/assets/fonts/Neutra2Text-Book.ttf') format("truetype");
}

@font-face {
    font-family: "Neutra2Text-BookItalic";
    src: url('/assets/fonts/Neutra2Text-BookItalic.ttf') format("truetype");
}

@font-face {
    font-family: "Neutra2Text-Demi";
    src: url('/assets/fonts/Neutra2Text-Demi.ttf') format("truetype");
}


/** --- INTER --- */
@font-face {
    font-family: "Inter24pt-Black";
    src: url('/assets/fonts/Inter-Black.otf') format("opentype");
}

@font-face {
    font-family: "Inter24pt-Bold";
    src: url('/assets/fonts/Inter-Bold.otf') format("opentype");
}

@font-face {
    font-family: "Inter24pt-ExtraBold";
    src: url('/assets/fonts/Inter-ExtraBold.otf') format("opentype");
}

@font-face {
    font-family: "Inter24pt-SemiBold";
    src: url('/assets/fonts/Inter-SemiBold.otf') format("opentype");
}

@font-face {
    font-family: "Inter24pt-Medium";
    src: url('/assets/fonts/Inter-Medium.otf') format("opentype");
}

@font-face {
    font-family: "Inter24pt-Regular";
    src: url('/assets/fonts/Inter-Regular.otf') format("opentype");
}


/** --- font-styles --- */

.font__neutra_bold {
    font-family: 'Neutra2Text-Bold';
}

.font__neutra_book {
    font-family: 'Neutra2Text-Book';
}

.font__neutra_book-italic {
    font-family: 'Neutra2Text-BookItalic';
}

.font__neutra_demi {
    font-family: 'Neutra2Text-Demi';
}


.font__inter_black {
    font-family: 'Inter24pt-Black';
}

.font__inter_bold {
    font-family: 'Inter24pt-Bold';
}

.font__inter_extrabold {
    font-family: 'Inter24pt-ExtraBold';
}

.font__inter_semibold {
    font-family: 'Inter24pt-SemiBold';
}

.font__inter_medium {
    font-family: 'Inter24pt-Medium';
}

.font__inter_regular {
    font-family: 'Inter24pt-Regular';
}
