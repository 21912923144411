// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();

// Note: Color palettes are generated from primary: #33e578
$_palettes: (
  primary: (
    0: #000000,
    10: #00210b,
    20: #003917,
    25: #00461e,
    30: #005225,
    35: #00602c,
    40: #006d33,
    50: #008942,
    60: #00a751,
    70: #00c561,
    80: #2fe376,
    90: #63ff93,
    95: #c4ffcb,
    98: #eaffe9,
    99: #f5fff2,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #00210b,
    20: #003917,
    25: #00461e,
    30: #015225,
    35: #155f2f,
    40: #256b3a,
    50: #408551,
    60: #5a9f69,
    70: #74bb82,
    80: #8fd79b,
    90: #aaf4b5,
    95: #c4ffcb,
    98: #eaffe9,
    99: #f5fff2,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #001f29,
    20: #003545,
    25: #004154,
    30: #004d63,
    35: #005a73,
    40: #006783,
    50: #0081a4,
    60: #1c9cc4,
    70: #47b7e0,
    80: #68d3fd,
    90: #bce9ff,
    95: #dff4ff,
    98: #f3faff,
    99: #fafcff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #151e16,
    20: #2a332a,
    25: #353e35,
    30: #404940,
    35: #4c554b,
    40: #586157,
    50: #707a6f,
    60: #8a9388,
    70: #a4aea2,
    80: #c0c9bd,
    90: #dce5d9,
    95: #eaf3e7,
    98: #f2fcef,
    99: #f5fff2,
    100: #ffffff,
    4: #081009,
    6: #0d150e,
    12: #19221a,
    17: #232c24,
    22: #2e372e,
    24: #333b33,
    87: #d3ddd0,
    92: #e1ebde,
    94: #e7f1e4,
    96: #edf6ea,
  ),
  neutral-variant: (
    0: #000000,
    10: #111f13,
    20: #263427,
    25: #313f32,
    30: #3c4a3d,
    35: #485648,
    40: #536254,
    50: #6c7b6c,
    60: #859585,
    70: #a0af9f,
    80: #bbcbb9,
    90: #d7e7d5,
    95: #e5f5e3,
    98: #edfeeb,
    99: #f5fff2,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes,  neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$custom-theme: mat.define-theme((
  color: (
    theme-type: dark,
    primary: $_primary,
    tertiary: $_tertiary,
  )
));

:root {
  @include mat.all-component-themes($custom-theme);
}
